import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="layout"
export default class extends Controller {
  static targets = [
    "sidebar",
    "sidebarToggler",
    "homeLink",
    "missionLink",
    "adminLink",
  ];

  toggleSidebar(e) {
    e.preventDefault();

    if (window.matchMedia("(min-width: 992px)").matches) {
      this.element.classList.toggle("sidebar-folded");
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      this.element.classList.toggle("sidebar-open");
    }

    this.sidebarTogglerTarget.classList.toggle("active");
    this.sidebarTogglerTarget.classList.toggle("not-active");
  }

  toggleHomeLinks(e) {
    e.preventDefault();

    this.homeLinkTargets.forEach((link) => {
      link.classList.toggle("d-none");
    });
  }

  toggleMissionLinks(e) {
    e.preventDefault();

    this.missionLinkTargets.forEach((link) => {
      link.classList.toggle("d-none");
    });
  }

  toggleAdminLinks(e) {
    e.preventDefault();

    this.adminLinkTargets.forEach((link) => {
      link.classList.toggle("d-none");
    });
  }
}

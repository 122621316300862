import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = [];

  initialize() {
    mapboxgl.accessToken = this.element.getAttribute("data-token");
    this.markersData = JSON.parse(this.element.getAttribute("data-markers"));
    this.markersCoordinates = [];
    this.markers = [];

    this.element.style.height = "800px";
  }

  connect() {
    const latitude = this.element.getAttribute("data-latitude");
    const longitude = this.element.getAttribute("data-longitude");

    this.map = new mapboxgl.Map({
      container: this.element.id,
      interactive: true,
      zoom: 12,
      style: "mapbox://styles/mapbox/streets-v12",
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    for (let markerData of this.markersData) {
      let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<h6><a href="${markerData.url}">${markerData.text}</a></h6>`
      );

      let marker = new mapboxgl.Marker({
        color: "#f36",
      })
        .setLngLat([markerData.longitude, markerData.latitude])
        .setPopup(popup)
        .addTo(this.map);

      this.markers.push(marker);
      this.markersCoordinates.push(
        new mapboxgl.LngLat(markerData.longitude, markerData.latitude)
      );
    }

    this.fitBounds();
  }

  fitBounds() {
    let bounds = new mapboxgl.LngLatBounds();
    for (let coordinate of this.markersCoordinates) {
      bounds.extend(coordinate);
    }
    this.map.fitBounds(bounds, { padding: 50 });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  initialize() {
    this.batch = this.element.getAttribute("data-batch");
  }

  confirmBatch() {
    if (window.confirm(__("Check all related missions?"))) {
      for (let checkbox of document.querySelectorAll(
        `input.batch-${this.batch}`
      )) {
        if (this.checkboxTarget.checked) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
      }
    }
  }
}

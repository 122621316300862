import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.contentTarget.classList.add("d-none");
  }

  toggle(e) {
    e.preventDefault();
    this.contentTarget.classList.toggle("d-none");
  }
}

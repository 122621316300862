import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import Trix from "trix";
import "@rails/actiontext";

// Translation files
import { enLocale } from "./locale/en/app";
import { frLocale } from "./locale/fr/app";

import Jed from "jed";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Selectize from "selectize/dist/js/selectize";
import flatpickr from "flatpickr/dist/flatpickr";
import "flatpickr/dist/l10n/fr";
import PerfectScrollbar from "perfect-scrollbar";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.fr";
import "@fortawesome/fontawesome-free/js/all";

import "./channels";
import "./controllers";

Rails.start();

(function () {
  let locales = {
    en: enLocale,
    fr: frLocale,
  };

  let locale = document.getElementsByTagName("html")[0].lang;

  locale = locale.replace(/-/g, "_");

  let i18n = new Jed(locales[locale] || {});

  window.__ = function () {
    return i18n.gettext.apply(i18n, arguments);
  };

  window.n__ = function () {
    return i18n.ngettext.apply(i18n, arguments);
  };

  window.s__ = function (key) {
    return window.__(key).split("|").pop();
  };

  window.gettext = window.__;
  window.ngettext = window.n__;
  window.sgettext = window.s__;

  window.i18n = i18n;

  document.addEventListener("turbo:load", () => {
    window.App || (window.App = {});

    // Initializing bootstrap tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Select inputs
    if (document.querySelector(".select-search") !== null) {
      window.App["selectizeSearches"] = $(".select-search").selectize();
    } else {
      window.App["selectizeSearches"] = [];
    }

    if (document.querySelector(".select-tokenizer") !== null) {
      window.App["selectizeTokenizers"] = $(".select-tokenizer")
        .selectize({
          delimiter: ",",
          persist: false,
          plugins: ["remove_button"],
          create: function (input) {
            return {
              value: input,
              text: input,
            };
          },
        })
        .toArray();
    } else {
      window.App["selectizeTokenizers"] = [];
    }

    // Flatpickr
    flatpickr(".datepicker", {
      dateFormat: __("m-d-Y"),
      locale: document.getElementsByTagName("html")[0].lang,
    });

    flatpickr(".datespicker", {
      dateFormat: __("m-d-Y"),
      mode: "range",
      locale: document.getElementsByTagName("html")[0].lang,
    });

    flatpickr(".mdatespicker", {
      dateFormat: __("m-d-Y"),
      minuteIncrement: 15,
      mode: "multiple",
      locale: document.getElementsByTagName("html")[0].lang,
    });

    flatpickr(".datetimepicker", {
      enableTime: true,
      dateFormat: __("m-d-Y H:i"),
      time_24hr: true,
      minuteIncrement: 15,
      locale: document.getElementsByTagName("html")[0].lang,
    });

    flatpickr(".datetimepicker-midnight", {
      enableTime: true,
      dateFormat: s__("m-d-Y H:i"),
      time_24hr: true,
      minuteIncrement: 15,
      locale: i18n.locale,
      defaultHour: 0,
      defaultMinute: 0,
    });

    flatpickr(".timepicker", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minuteIncrement: 15,
      locale: document.getElementsByTagName("html")[0].lang,
    });

    // Inputs formatting
    if (document.querySelectorAll("input[type='tel']").length > 0) {
      window.App["phoneCleave"] = new Cleave("input[type='tel']", {
        phone: true,
        phoneRegionCode: document.getElementsByTagName("html")[0].lang,
      });
    }

    // Applying perfect-scrollbar
    if (document.querySelector(".sidebar .sidebar-body") !== null) {
      const sidebarBodyScroll = new PerfectScrollbar(".sidebar-body");
    }
    if (document.querySelector(".content-nav-wrapper") !== null) {
      const contentNavWrapper = new PerfectScrollbar(".content-nav-wrapper");
    }

    // Sidebar toggle to sidebar-folded
    // if (document.querySelector(".sidebar-toggler") !== null) {
    //   window.App["sidebarToggleListener"] = document
    //     .querySelector(".sidebar-toggler")
    //     .addEventListener("click", (e) => {
    //       e.target.classList.toggle("active");
    //       e.target.classList.toggle("not-active");

    //       if (window.matchMedia("(min-width: 992px)").matches) {
    //         e.preventDefault();
    //         document.querySelector("body").toggleClass("sidebar-folded");
    //       } else if (window.matchMedia("(max-width: 991px)").matches) {
    //         e.preventDefault();
    //         document.querySelector("body").toggleClass("sidebar-open");
    //       }
    //     });
    // }

    // Checkboxes and radios
    $(".form-check label, .form-radio label").append(
      '<i class="input-frame"></i>'
    );

    // File inputs
    $(".file-upload-browse").on("click", function (e) {
      let file = $(this)
        .parent()
        .parent()
        .parent()
        .find(".file-upload-default");

      file.trigger("click");
    });

    $(".file-upload-default").on("change", function () {
      $(this)
        .parent()
        .find(".form-control")
        .val(
          $(this)
            .val()
            .replace(/C:\\fakepath/i, "")
        );
    });
  });

  document.addEventListener("turbo:before-cache", () => {
    for (const selectizeSearch of window.App.selectizeSearches) {
      selectizeSearch.selectize.destroy();
    }

    for (const selectizeTokenizer of window.App.selectizeTokenizers) {
      selectizeTokenizer.selectize.destroy();
    }

    if (window.App["phoneCleave"] !== undefined) {
      window.App["phoneCleave"].destroy();
    }

    if (document.querySelector(".sidebar-toggler") !== null) {
      document
        .querySelector(".sidebar-toggler")
        .removeEventListener("click", window.App["sidebarToggleListener"]);
    }
  });

  Trix.config.textAttributes.red = {
    style: { color: "#c0392b" },
    parser: (element) => {
      return element.style.color === "#c0392b";
    },
    inheritable: true,
  };

  Trix.config.textAttributes.green = {
    style: { color: "#27ae60" },
    parser: (element) => {
      return element.style.color === "#27ae60";
    },
    inheritable: true,
  };

  Trix.config.textAttributes.blue = {
    style: { color: "#2980b9" },
    parser: (element) => {
      return element.style.color === "#2980b9";
    },
    inheritable: true,
  };

  addEventListener("trix-initialize", (event) => {
    let buttonHTML = `
        <button type="button" data-trix-attribute="red" class="trix-button trix-button--icon trix-button--icon-red">R</button>
        <button type="button" data-trix-attribute="green" class="trix-button trix-button--icon trix-button--icon-green">G</button>
        <button type="button" data-trix-attribute="blue" class="trix-button trix-button--icon trix-button--icon-blue">B</button>
`;

    event.target.toolbarElement
      .querySelector(".trix-button-group.trix-button-group--text-tools")
      .insertAdjacentHTML("beforeend", buttonHTML);
  });
})();

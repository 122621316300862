import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="file-attachment"
export default class extends Controller {
  open(e) {
    e.preventDefault();

    window.open(
      e.target.href,
      "_blank",
      "width=800,height=600,location=no,toolbar=no,menubar=no,resizable=yes,scrollbars=no,status=no,left=200,top=100"
    );
  }

  remove(e) {
    e.preventDefault();
    this.element.remove();
  }
}

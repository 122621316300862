import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  initialize() {
    this.message = this.element.getAttribute("data-message");
    this.altMessage = this.element.getAttribute("data-alt-message");
  }

  confirm() {
    if (this.checkboxTarget.checked) {
      if (window.confirm(this.message)) {
        this.checkboxTarget.checked = true;
      } else {
        this.checkboxTarget.checked = false;
      }
    } else {
      if (window.confirm(this.altMessage)) {
        this.checkboxTarget.checked = false;
      } else {
        this.checkboxTarget.checked = true;
      }
    }
  }
}

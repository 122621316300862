import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput", "address", "zipcode", "city", "country"];

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.searchInputTarget,
      { types: ["geocode"] }
    );

    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      let address = "";

      console.log(place);

      for (let component of place.address_components) {
        if (component.types.includes("street_number")) {
          address = `${component.long_name} ${address}`;
        } else if (component.types.includes("route")) {
          address = `${address} ${component.long_name}`;
        } else if (component.types.includes("sublocality")) {
          address = `${address} ${component.long_name}`;
        } else if (component.types.includes("locality")) {
          this.cityTarget.value = component.long_name;
        } else if (component.types.includes("postal_code")) {
          this.zipcodeTarget.value = component.long_name;
        } else if (component.types.includes("country")) {
          this.countryTarget.value = component.short_name.toLowerCase();
        }
      }

      this.addressTarget.value = address;
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr/dist/flatpickr";
import "flatpickr/dist/l10n/fr";

export default class extends Controller {
  static targets = [];

  connect() {
    flatpickr(this.element, {
      enableTime: true,
      dateFormat: __("m-d-Y H:i"),
      time_24hr: true,
      minuteIncrement: 15,
      locale: document.getElementsByTagName("html")[0].lang,
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import Selectize from "selectize/dist/js/selectize";

export default class extends Controller {
  static targets = ["select"];

  initialize() {
    this.searchURL = this.element.getAttribute("data-url");
  }

  connect() {
    const csrfToken = document.getElementsByName("csrf-token")[0].content;

    const $select = $(this.selectTarget).selectize({
      valueField: "id",
      labelField: "text",
      searchField: "text",
      create: false,
      render: {
        option: (item, escape) => {
          return `
            <div>${escape(item.text)}</div>
          `;
        }
      },
      load: (query, callback) => {
        if (!query.length || query.length < 3) return callback();

        fetch(this.searchURL + query, {
          credentials: "include",
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json"
          }
        })
          .then(response => response.json())
          .then(hits => {
            callback(hits);
          })
          .catch(err => {
            callback();
          });
      }
    });

    this.selectize = $select[0].selectize;
  }

  disconnect() {
    this.selectize.destroy();
  }
}

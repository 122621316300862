import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";

export default class extends Controller {
  static targets = ["content"];
  static values = { opened: Boolean, declared: Boolean, payDone: Boolean, plannableId: String };

  connect() {
    this.setupStyle();

    if (window.notyf === undefined) {
      window.notyf = new Notyf({
        position: {
          x: "center",
          y: "top",
        },
      });
    }

    this.element.addEventListener("turbo:submit-end", (event) => {
      this.setupStyle();
      this.refreshFooter();

      window.notyf.success("Sauvegardé");
    });
  }

  setupStyle() {
    if (this.openedValue) {
      this.contentTarget.style.display = "block";
    } else {
      this.contentTarget.style.display = "none";
    }

    if (this.declaredValue) {
      this.element.parentNode.parentNode.classList.add("bg-success");
    } else {
      this.element.parentNode.parentNode.classList.remove("bg-success");
    }

    for (const cell of document.querySelectorAll(`.plannable-cell-${this.plannableIdValue}`)) {
      if (this.payDoneValue) {
        cell.classList.add("planning-pay-done") ;
      } else {
        cell.classList.remove("planning-pay-done") ;
      }
    }
  }

  toggle(event) {
    event.preventDefault();

    if (this.contentTarget.style.display == "none") {
      this.contentTarget.style.display = "block";
    } else {
      this.contentTarget.style.display = "none";
    }
  }

  refreshFooter() {
    const footer = document.querySelector(`#planning-billable-footer-${this.plannableIdValue}`);
    footer.src = `${window.location}?t=${Date.now()}`;
  }
}

import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr/dist/flatpickr";
import "flatpickr/dist/l10n/fr";

export default class extends Controller {
  static targets = [];

  connect() {
    flatpickr(this.element, {
      dateFormat: __("m-d-Y"),
      locale: document.getElementsByTagName("html")[0].lang,
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "output"];

  connect() {
    this.inputTarget.addEventListener("change", (e) => {
      let names = [...e.target.files].map((file) => {
        return `<li>${file.name}</li>`;
      });

      this.outputTarget.innerHTML = `<ul>${names.join("")}</ul>`;
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = [];

  initialize() {
    mapboxgl.accessToken = this.element.getAttribute("data-token");

    this.element.style.height = "250px";
  }

  connect() {
    const latitude = this.element.getAttribute("data-latitude");
    const longitude = this.element.getAttribute("data-longitude");

    this.map = new mapboxgl.Map({
      container: this.element.id,
      interactive: true,
      attributionControl: false,
      locale: window.i18n.locale,
      zoom: 12,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [longitude, latitude],
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(this.map);
  }
}
